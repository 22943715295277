<template>
  <div class="w-100">
    <basic-subheader v-if="isWritePermission">
      <template v-slot:actions>
        <b-row>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-button class="btn mr-4" type="button" @click="save">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
                </span>
                Lưu lại
              </b-button>
              <b-button class="btn btn-success" type="button" @click="addRole">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
                </span>
                Thêm mới
              </b-button>
            </div>
          </b-col>
        </b-row>
      </template>
    </basic-subheader>
    <div class="mt-6 mx-7">
      <div class="wrap__description__permission">
        <b-card>
          <div class="role">
            <inline-svg
              class="svg-icon"
              src="/media/svg/Permission-role/User-circle-gear.svg"
            />
          </div>
          <h6>Phân quyền chức năng:</h6>
          <p>
            <b>Đọc: </b>Cho phép người dùng truy cập và xem các bản ghi thuộc
            module đang được đề cập
          </p>
          <p>
            <b>Ghi: </b>Cho phép người dùng tạo mới và thay đổi chi tiết các bản
            ghi thuộc module đang được đề cập
          </p>
        </b-card>
        <b-card>
          <div class="permission">
            <inline-svg
              class="svg-icon"
              src="/media/svg/Permission-role/Hard-drives.svg"
            />
          </div>
          <h6>Phân quyền dữ liệu:</h6>
          <p>
            <b>Tất cả: </b>Cho phép người dùng xem toàn bộ dữ liệu của module
            đang được đề cập
          </p>
          <p>
            <b>Dữ liệu được gán: </b>Cho phép người dùng xem các dữ liệu được
            gán
          </p>
        </b-card>
      </div>
    </div>
    <Wrapper>
      <div>
        <b-overlay :show="loading">
          <v-data-table
            v-if="headers && headers.length"
            :headers="headers"
            :items="permissions"
            item-key="id"
            class="elevation-1"
            hide-default-footer
            :show-group-by="false"
            :items-per-page="-1"
            group-by="order"
          >
            <template v-slot:header.role="{ header }">
              <div class="py-3">
                {{ header.text }}
                <div v-if="isWritePermission">
                  <action-dropdown
                    v-if="header.code !== 'Admin'"
                    :value="header"
                    :show_delete="false"
                    :show_edit="false"
                    :show_copy="false"
                    :show_view="false"
                    class="header-dropdown"
                  >
                    <b-dropdown-text
                      tag="div"
                      class="navi-item cursor-pointer"
                      @click="selectedAll(!header.checked, header)"
                    >
                      <a class="navi-link cursor-pointer">
                        <span
                          class="menu-icon svg-icon svg-icon-sm text-primary"
                        >
                          <inline-svg
                            v-if="!header.checked"
                            class="svg-icon"
                            src="/media/svg/icons/Neolex/Basic/Check-all.svg"
                          />
                          <inline-svg
                            v-else
                            class="svg-icon"
                            src="/media/svg/icons/Neolex/Basic/Uncheck-all.svg"
                          />
                        </span>
                        <span class="success navi-text text-primary">
                          {{ header.checked ? 'Bỏ chọn' : 'Chọn' }} tất cả
                        </span>
                      </a>
                    </b-dropdown-text>
                    <b-dropdown-divider />
                    <b-dropdown-text
                      tag="div"
                      class="navi-item cursor-pointer"
                      @click="showPopupConfirmDelete(header)"
                    >
                      <a class="navi-link cursor-pointer">
                        <span
                          class="menu-icon svg-icon svg-icon-sm text-danger"
                        >
                          <inline-svg
                            class="svg-icon"
                            src="/media/svg/icons/Neolex/Basic/trash-2.svg"
                          />
                        </span>
                        <span class="success navi-text text-danger">
                          Xóa vai trò
                        </span>
                      </a>
                    </b-dropdown-text>
                  </action-dropdown>
                </div>
              </div>
            </template>
            <template v-slot:group.header="{ toggle, group, isOpen }">
              <td :colspan="headers.length" class="pl-0 no-background">
                <div class="d-flex align-items-center">
                  <v-btn @click="toggle" :ref="group" :data-open="isOpen" icon>
                    <v-icon
                      >mdi-{{ isOpen ? 'chevron-down' : 'chevron-up' }}</v-icon
                    >
                  </v-btn>
                  <span class="font-weight-boldest ml-4">{{
                    convertGroup(group)
                  }}</span>
                </div>
              </td>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td class="px-15">{{ item.name }}</td>
                <template v-for="(role, i) in item.roles">
                  <td :key="i" style="width: 10%" class="text-center">
                    <div class="d-flex justify-content-center">
                      <v-checkbox
                        v-model="role.checked"
                        color="#008479"
                        dense
                        class="checkbox-role"
                        :disabled="role.roleCode === 'Admin'"
                        @change="editRole($event, role)"
                      ></v-checkbox>
                    </div>
                  </td>
                </template>
              </tr>
            </template>
          </v-data-table>
        </b-overlay>
      </div>
    </Wrapper>
    <ModalRole @add-success="addSuccess" />
  </div>
</template>
<script>
import groupBy from 'lodash/groupBy';
import { MODAL_STATUS, PAGE_CODE } from '@/core/plugins/constants';
export default {
  name: 'permission_role',
  components: {
    ModalRole: () => import('./components/ModalRole.vue'),
  },
  data() {
    return {
      headers: [],
      permissions: [],
      selectedRoles: [],
      loading: false,
      PAGE_CODE,
    };
  },
  computed: {
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.getHeaders();
      await this.getSelectedRoles();
      await this.getPermissions();
      this.loading = false;
    },
    async getHeaders() {
      try {
        const { meta, error, data } = await this.$api.get('/Role');
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }

        this.headers = data
          .map((el) => {
            return {
              text: el.name,
              align: 'center',
              sortable: false,
              value: 'role',
              id: el.id,
              code: el.code,
            };
          })
          .reverse();

        this.headers.unshift({
          text: 'Quyền',
          align: 'start',
          sortable: false,
          value: 'permission',
          width: '10%',
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      }
    },
    async getPermissions() {
      try {
        const { meta, error, data } = await this.$api.get(
          '/PermissionRole/Permissions',
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        this.permissions = data.map((el) => {
          return {
            ...el,
            roles: this.setValuaCheckbox(el.id),
          };
        });
        const groupRole = groupBy(this.selectedRoles, 'roleId');
        this.headers.forEach((role) => {
          if (!role.id) return;
          const permission = groupRole[role.id] || [];
          if (permission.length === this.permissions.length) {
            role.checked = true;
          } else {
            role.checked = false;
          }
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      }
    },
    async getSelectedRoles() {
      try {
        const { meta, error, data } = await this.$api.get('/PermissionRole');
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }

        this.selectedRoles = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      }
    },
    addRole() {
      this.$bvModal.show('modal-role');
    },
    addSuccess() {
      // reload data
      this.loadData();
    },
    getValueCheckbox(role, permission) {
      return new Promise((resolve) => {
        const selectedRole = this.selectedRoles.find(
          (el) => el.roleId === role.id && el.permissionId === permission.id,
        );
        resolve(selectedRole ? true : false);
      });
    },
    setValuaCheckbox(permissionId) {
      let roles = [...this.headers].filter((el) => el.id);
      roles = roles.map((role) => {
        const selected = this.selectedRoles.find(
          (el) => el.permissionId === permissionId && el.roleId === role.id,
        );
        return {
          roleId: role.id,
          checked: selected ? true : false,
          modelStatus: MODAL_STATUS.UNCHANGE,
          oldChecked: selected ? true : false,
          permissionId,
          roleCode: role.code,
        };
      });
      return roles;
    },
    async editRole(value, role) {
      if (role.oldChecked === value) {
        role.modelStatus = MODAL_STATUS.UNCHANGE;
      } else {
        role.modelStatus = value ? MODAL_STATUS.NEW : MODAL_STATUS.DELETE;
      }
      const header = this.headers.find((el) => el.id === role.roleId);
      let roles = [];
      this.permissions.forEach((permission) => {
        roles = [...roles, ...permission.roles];
      });
      const permissionByRoleId = roles.filter(
        (el) => el.roleId === role.roleId && el.checked,
      );
      if (permissionByRoleId.length === this.permissions.length) {
        header.checked = true;
      } else {
        header.checked = false;
      }
    },
    selectedAll(value, header) {
      this.permissions.forEach((permission) => {
        permission.roles.forEach((role) => {
          if (role.roleId === header.id) {
            role.checked = value;
            if (role.oldChecked === value) {
              role.modelStatus = MODAL_STATUS.UNCHANGE;
            } else {
              role.modelStatus = value ? MODAL_STATUS.NEW : MODAL_STATUS.DELETE;
            }
          }
        });
      });
      header.checked = value;
    },
    async save() {
      this.loading = true;
      let params = [];
      this.permissions.forEach((el) => {
        const changedRoles = el.roles.filter(
          (role) => role.modelStatus !== MODAL_STATUS.UNCHANGE,
        );
        params = [...params, ...changedRoles];
      });

      if (!params.length) return;

      params = params.map((el) => {
        return {
          permissionId: el.permissionId,
          roleId: el.roleId,
          modelStatus: el.modelStatus,
        };
      });
      try {
        const { meta, error } = await this.$api.put('/PermissionRole', params);
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        this.loadData();
        this.$store.dispatch('GET_PERMISSIONS');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      }
      this.loading = false;
    },
    showPopupConfirmDelete(role) {
      this.$swal({
        title: '',
        text: `Bạn có chắc muốn xóa ${role.text} không?`,
        icon: '/media/svg/icons/SweetAlert/alert-triangle-red.svg',
        buttons: {
          cancel: {
            text: 'Hủy',
            value: false,
            visible: true,
            className: 'btn btn-secondary',
            closeModal: true,
          },
          confirm: {
            text: 'Chắc chắn',
            value: true,
            visible: true,
            className: `btn btn-inactive`,
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.deleteRole(role);
          }
        }.bind(this),
      );
    },
    async deleteRole(role) {
      try {
        this.loading = true;
        const { meta, error } = await this.$api.delete(`/Role/${role.id}`);
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        this.loadData();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    convertGroup(group) {
      const permissions = this.permissions.filter((el) => el.order === group);
      return permissions[0].groupName;
    },
  },
};
</script>
<style lang="scss">
.checkbox-role {
  .v-input__control .v-messages {
    display: none;
  }
}

.header-dropdown {
  button {
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
.role {
  width: 60px;
  height: 60px;
  background: #def2f1;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}
.permission {
  width: 60px;
  height: 60px;
  background: #fff7c0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.wrap__description__permission {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
}
</style>
